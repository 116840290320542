export const PATHS = {
  HOME: "/",
  POINT_HISTORY: "/point-history",
  ACCOUNT_SETTING:"/account-setting",
  FAQ:"/faq",
  SYORUI:"/syorui",
  PRIVACY_POLICY:"/privacy-policy",
  // ユーザー
  // AUTH_LOGIN: "/",
  AUTH_PASSWORD_RESET: "/password-reset",
  AUTH_PASSWORD_FORGET: "/password-forget",
  AUTH_ACCOUNT: "/account",
  SETTING_USER: "/setting-user",
  // 管理者
  PRONPT: "/prompt",
  ACCOUNT: "/company",


} as const;
