import React, { FC, useState, useEffect, useCallback } from 'react';
import {
  Paper,
  Grid,
  Typography,
  Box,
  Button,
  AppBar,
  Toolbar,
  CircularProgress
} from '@mui/material'
import { useTheme } from '@mui/material/styles';
import { useSwipeable } from 'react-swipeable';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { komutenState } from '../recoil/komutenState';
import { customerState } from '../recoil/customerState';
import { getPointHistory } from '../api/pointHistory';
import PointRankCard from '../components/pointRankCard';

export type PointHistoryProps = object;

const PointHistory: FC<PointHistoryProps> = () => {
  const navigate = useNavigate();
  const handlers = useSwipeable({
    onSwipedRight: () => navigate(-1)
  });
  const komuten = useRecoilValue(komutenState);
  const customer = useRecoilValue(customerState);
  const [pointHistory, setPointHistory] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);


  // ポイント履歴をフェッチしてステートを更新する関数
  const fetchPointHistory = useCallback(async () => {
    const customerId = customer?.id || 0;
    if (!customerId) return;
    const pointHistoryData = await getPointHistory(customerId);
    setIsLoading(false); // データ取得が終了またはエラーが発生したらローディング状態をfalseに設定
    if (pointHistoryData !== null) {
      let runningTotal = 0;
      const calculatedPointHistory = pointHistoryData.map((item: { change_point: number; }) => {
        runningTotal += item.change_point;
        return { ...item, zandaka: runningTotal };
      });
      setPointHistory(calculatedPointHistory.reverse());
    }
  }, [customer]);


  useEffect(() => {
    document.title = komuten?.name || "";
    if (customer && customer.id) {
      fetchPointHistory();
    }
  }, [komuten, customer, fetchPointHistory]);

  const INITIAL_ITEM_COUNT = 5;
  // 表示アイテム数の状態とその更新関数
  const [visibleItemCount, setVisibleItemCount] = useState(INITIAL_ITEM_COUNT);

  const theme = useTheme();

  // もっと見るボタンをクリックしたときの処理
  const handleShowMore = () => {
    setVisibleItemCount(prevCount => prevCount + 5); // 5つずつ増やす
  };

  return (
    <div {...handlers} style={{ height: '100%', width: '100%' }}>
      <>
        <AppBar position="static" sx={{ bgcolor: theme.palette.primary.main, elevation: 0, boxShadow: 'none' }}>
          <Toolbar sx={{ minHeight: '48px' }}>
            <Typography variant="subtitle1" color="inherit" noWrap sx={{ flexGrow: 1, textAlign: 'center' }}>
              ポイント履歴
            </Typography>
          </Toolbar>
        </AppBar>
        <div style={{ padding: '16px 0' }}>
          {/* <Card
            sx={{
              boxShadow: 0,
              width: '90%',
              margin: '0 auto',
              marginBottom: '16px',
              borderRadius: 4,
              background: rankColorTheme['platinum'],
              position: 'relative',
              overflow: 'hidden',
              '&:before': {
                content: '""',
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                background: 'linear-gradient(to right, rgba(255,255,255,0.3), rgba(255,255,255,0.8), rgba(255,255,255,0.3))',
                transform: 'rotate(30deg)',
                zIndex: 0,
              }
            }}
          >
            <CardContent
              sx={{
                textAlign: 'center',
                color: theme.palette.secondary.main,
                position: 'relative',
                zIndex: 1,
              }}
            >
              <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                <IconButton onClick={handleClickOpen} sx={{ color: theme.palette.secondary.main }}>
                  <SavingsOutlined />
                  <Typography
                    component="span"
                    sx={{
                      ml: 1, fontSize: '15px', color: theme.palette.secondary.main,
                      textDecoration: 'underline',
                    }}
                  >
                    ポイント獲得について
                  </Typography>
                </IconButton>
                <PointGetDialog open={open} handleClose={handleClose} />
              </Box>
              <Typography
                variant="body2"
                sx={{ textAlign: 'left', pl: 3, fontWeight: 'bold', color: theme.palette.secondary.main }}
              >
                現在のランク
              </Typography>
              <Typography
                variant="h5"
                sx={{
                  fontWeight: 'bold',
                  mb: 1,
                  padding: '0 8px',
                }}
              >
                プラチナ
              </Typography>
              <Typography
                variant="body2"
                sx={{ textAlign: 'left', pl: 3, fontWeight: 'bold', mb: 1, color: theme.palette.secondary.main }}
              >
                保有ポイント
              </Typography>
              {isLoading ? (
                <Box sx={{ height: 56, mb: 1 }}></Box>
              ) : (
                <Typography
                  variant="h4"
                  sx={{ fontWeight: 'bold', mb: 1, color: theme.palette.secondary.main }}
                >
                  {hoyuPoint.toLocaleString()}pt
                </Typography>
              )}
              <Typography
                sx={{ textAlign: 'right', fontSize: '15px', mb: 0, color: theme.palette.secondary.main }}
              >
                {pointRate}
              </Typography>
            </CardContent>
          </Card> */}
          <PointRankCard showPointInfo={true} />
          {isLoading ? (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
              <CircularProgress />
            </div>
          ) : (
            <Grid container spacing={1} sx={{ padding: 1, }}>
              {pointHistory.length > 0 ? (
                pointHistory.slice(0, visibleItemCount).map((row, index) => (
                  <Grid item xs={12} key={index}>
                    <Paper
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        padding: 2,
                        backgroundColor: '#FFFF',
                        borderRadius: 4,
                        boxShadow: 0,
                        height: '100%',
                      }}
                    >
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                        <Typography variant="body2" sx={{ color: 'grey', fontSize: '0.875rem' }}>
                          {new Date(row.changed_at).toLocaleDateString()}
                        </Typography>
                        <Typography variant="body2" sx={{ color: 'grey', fontSize: '0.9rem' }}>
                          {`残高：${row.zandaka.toLocaleString()}pt`}
                        </Typography>
                      </Box>
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
                        <Typography variant="body2" sx={{ fontSize: '1rem' }}>
                          {row.content}
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{
                            color: row.change_point > 0 ? '#4CAF50' : '#F44336',
                            fontWeight: 'bold',
                            fontSize: '0.875rem',
                            alignSelf: 'flex-end',
                          }}
                        >
                          {`${row.change_point > 0 ? '+' : ''}${row.change_point.toLocaleString()}pt`}
                        </Typography>
                      </Box>
                    </Paper>
                  </Grid>
                ))
              ) : (
                <Grid item xs={12}>
                  <Typography align="center" sx={{ mt: 2 }}>
                    まだポイントの履歴はありません。
                  </Typography>
                </Grid>
              )}
              {pointHistory.length > 0 && visibleItemCount < pointHistory.length && (
                <Grid item xs={12} display="flex" justifyContent="center">
                  <Button onClick={handleShowMore}>もっと見る</Button>
                </Grid>
              )}
            </Grid>
          )}
        </div>
      </>
    </div>
  );
}

export default PointHistory;
