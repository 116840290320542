import React, { useState, useEffect } from 'react';
import {
  List,
  ListItem,
  Box,
  Typography,
  Divider,
  TextField,
  Button,
  Card,
  CardContent,
  AppBar,
  Toolbar,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useSwipeable } from 'react-swipeable';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { customerState } from '../recoil/customerState';
import { komutenState } from '../recoil/komutenState';
import { updateAccountSetting } from '../api/customer';

const AccountSetting: React.FC = () => {
  const navigate = useNavigate();
  const handlers = useSwipeable({
    onSwipedRight: () => navigate(-1)
  });
  const theme = useTheme();
  const komuten = useRecoilValue(komutenState);
  const [customer, setCustomer] = useRecoilState(customerState);
  const [name, setName] = useState("");
  const [name_kana, setFurigana] = useState("");
  const [post_number, setPostalCode] = useState("");
  const [zyusyo, setAddress] = useState("");
  const [kotei_tell_number, setKoteiTellNumber] = useState("");
  const [tell_number, setTellNumber] = useState("");

  useEffect(() => {
    document.title = komuten?.name || "";
    if (customer) {
      setName(customer.name);
      setFurigana(customer.name_kana);
      setPostalCode(customer.post_number);
      setAddress(customer.zyusyo);
      setKoteiTellNumber(customer.kotei_tell_number);
      setTellNumber(customer.tell_number);
    }
  }, [komuten, customer]);

  const [isEditing, setIsEditing] = useState(false);

  const formatWithHyphen = (value: string, positions: number[]): string => {
    let formattedValue = '';
    let index = 0;
    for (const char of value) {
      if (index < positions.length && positions[index] === formattedValue.length) {
        formattedValue += '-';
        index++;
      }
      formattedValue += char;
    }
    return formattedValue;
  };

  const handleInfoChange = (field: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    let value = event.target.value;
    if (field === 'post_number') {
      value = value.replace(/\D/g, '').slice(0, 7);
      setPostalCode(value);
    } else if (field === 'kotei_tell_number') {
      value = value.replace(/[^0-9-]/g, '');
      setKoteiTellNumber(value);
    } else if (field === 'tell_number') {
      value = value.replace(/[^0-9-]/g, '');
      setTellNumber(value);
    } else if (field === 'name') {
      setName(value);
    } else if (field === 'name_kana') {
      setFurigana(value);
    } else if (field === 'zyusyo') {
      setAddress(value);
    }
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleSaveClick = async () => {
    try {
      const customerData = {
        customer_id: customer?.id,
        name,
        name_kana,
        post_number,
        zyusyo,
        kotei_tell_number,
        tell_number,
      };
      await updateAccountSetting(customerData);
      if (customer !== null) {
        setCustomer({
          ...customer,
          ...customerData,
        });
      }
      setIsEditing(false);
    } catch (error) {
      alert(`更新に失敗しました。もう一度お試しください。`);
    }
  };

  return (
    <div {...handlers} style={{ height: '100%', width: '100%' }}>
      <>
        <AppBar position="static" sx={{ bgcolor: theme.palette.primary.main, elevation: 0, boxShadow: 'none' }}>
          <Toolbar sx={{ minHeight: '48px' }}>
            <Typography variant="subtitle1" color="inherit" noWrap sx={{ flexGrow: 1, textAlign: 'center' }}>
              アカウント設定
            </Typography>
          </Toolbar>
        </AppBar>
        <Box sx={{ width: '90%', mx: 'auto', boxShadow: 'none', mt: theme.spacing(2) }}>
          <Card sx={{ width: '100%', boxShadow: 0, backgroundColor: (theme) => theme.palette.background.paper, borderRadius: 4 }}>
            <CardContent>
              <List component="nav" dense>
                <ListItem
                  sx={{
                    justifyContent: 'space-between',
                    alignItems: 'flex-start',
                    py: 2,
                    px: 2,
                    display: 'flex',
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                    <Typography variant="subtitle1" sx={{ flexShrink: 0, mr: 2, whiteSpace: 'nowrap' }}>
                      名前
                    </Typography>
                    {isEditing ? (
                      <TextField
                        sx={{ flexGrow: 1 }}
                        value={name}
                        onChange={handleInfoChange('name')}
                        variant="standard"
                        size="small"
                        autoFocus
                      />
                    ) : (
                      <Typography variant="body1" sx={{ flexGrow: 1, textAlign: 'right' }}>
                        {name}
                      </Typography>
                    )}
                  </Box>
                </ListItem>
                <Divider component="li" />
                <ListItem
                  sx={{
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    py: 2,
                    px: 2,
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                    <Typography variant="subtitle1" sx={{ flexShrink: 0, mr: 2, whiteSpace: 'nowrap' }}>
                      ふりがな
                    </Typography>
                    {isEditing ? (
                      <TextField
                        sx={{ flexGrow: 1 }}
                        value={name_kana}
                        onChange={handleInfoChange('name_kana')}
                        variant="standard"
                        size="small"
                      />
                    ) : (
                      <Typography variant="body1" sx={{ flexGrow: 1, textAlign: 'right' }}>
                        {name_kana}
                      </Typography>
                    )}
                  </Box>
                </ListItem>
                <Divider component="li" />
                <ListItem
                  sx={{
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    py: 2,
                    px: 2,
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                    <Typography variant="subtitle1" sx={{ flexShrink: 0, mr: 2, whiteSpace: 'nowrap' }}>
                      郵便番号
                    </Typography>
                    {isEditing ? (
                      <TextField
                        sx={{ flexGrow: 1 }}
                        value={post_number}
                        onChange={handleInfoChange('post_number')}
                        variant="standard"
                        size="small"
                        inputProps={{ inputMode: 'numeric', maxLength: 7 }}
                      />
                    ) : (
                      <Typography variant="body1" sx={{ flexGrow: 1, textAlign: 'right' }}>
                        {formatWithHyphen(post_number, [3])}
                      </Typography>
                    )}
                  </Box>
                </ListItem>
                <Divider component="li" />
                <ListItem
                  sx={{
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    py: 2,
                    px: 2,
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                    <Typography variant="subtitle1" sx={{ flexShrink: 0, mr: 2, whiteSpace: 'nowrap' }}>
                      住所
                    </Typography>
                    {isEditing ? (
                      <TextField
                        sx={{ flexGrow: 1 }}
                        value={zyusyo}
                        onChange={handleInfoChange('zyusyo')}
                        variant="standard"
                        size="small"
                      />
                    ) : (
                      <Typography variant="body1" sx={{ flexGrow: 1, textAlign: 'right' }}>
                        {zyusyo}
                      </Typography>
                    )}
                  </Box>
                </ListItem>
                <Divider component="li" />
                <ListItem
                  sx={{
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    py: 2,
                    px: 2,
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                    <Typography variant="subtitle1" sx={{ flexShrink: 0, mr: 2, whiteSpace: 'nowrap' }}>
                      固定電話番号
                    </Typography>
                    {isEditing ? (
                      <TextField
                        sx={{ flexGrow: 1 }}
                        value={kotei_tell_number}
                        onChange={handleInfoChange('kotei_tell_number')}
                        variant="standard"
                        size="small"
                        inputProps={{ pattern: "[0-9-]*" }}
                      />
                    ) : (
                      <Typography variant="body1" sx={{ textAlign: 'right', width: '100%' }}>
                        {kotei_tell_number}
                      </Typography>
                    )}
                  </Box>
                </ListItem>
                <Divider component="li" />
                <ListItem
                  sx={{
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    py: 2,
                    px: 2,
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                    <Typography variant="subtitle1" sx={{ flexShrink: 0, mr: 2, whiteSpace: 'nowrap' }}>
                      携帯電話番号
                    </Typography>
                    {isEditing ? (
                      <TextField
                        sx={{ flexGrow: 1 }}
                        value={tell_number}
                        onChange={handleInfoChange('tell_number')}
                        variant="standard"
                        size="small"
                        inputProps={{ pattern: "[0-9-]*" }}
                      />
                    ) : (
                      <Typography variant="body1" sx={{ textAlign: 'right', width: '100%' }}>
                        {tell_number}
                      </Typography>
                    )}
                  </Box>
                </ListItem>
                <Divider component="li" />
              </List>
              {isEditing ? (
                <Box textAlign="center" mt={2}>
                  <Button onClick={handleSaveClick} variant="contained" color="primary">
                    保存
                  </Button>
                </Box>
              ) : (
                <Box textAlign="center" mt={2}>
                  <Button onClick={handleEditClick} variant="outlined" color="primary">
                    編集
                  </Button>
                </Box>
              )}
            </CardContent>
          </Card>
        </Box>
      </>
    </div>
  );
};

export default AccountSetting;
