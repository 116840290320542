import React, { FC, useEffect, useState } from 'react';
import {
  Box,
  // Container,
  List,
  ListItem,
  ListItemText,
  useTheme,
  Divider,
  ListItemIcon,
  AppBar,
  Toolbar,
  Typography
} from '@mui/material';
import { useSwipeable } from 'react-swipeable';
import { useNavigate } from 'react-router-dom';
import { PictureAsPdf } from '@mui/icons-material';
import { useRecoilValue } from 'recoil';
import { customerState } from '../recoil/customerState';
import { komutenState } from '../recoil/komutenState';
import { fetchDocuments } from '../api/syorui';
import { getFilePublicUrl, getFileUrl } from '../service/storageService';

export type KeiyakushoProps = object

// 書類の型定義
interface Document {
  syorui_name: string;
  file_name: string;
  file_path: string;
  created_at: string;
}

const Keiyakusho: FC<KeiyakushoProps> = () => {
  const navigate = useNavigate();
  const handlers = useSwipeable({
    onSwipedRight: () => navigate(-1)
  });
  const theme = useTheme();
  const komuten = useRecoilValue(komutenState);
  const customer = useRecoilValue(customerState);
  const [documents, setDocuments] = useState<Document[]>([]);

  useEffect(() => {
    const init = async () => {
      try {
        document.title = komuten?.name || "";
        if (customer) {
          const customerId = customer.id;
          const documentsData = await fetchDocuments(customerId);
          if (documentsData.length === 0) {
            return;
          }
          const documentsWithUrl = await Promise.all(documentsData.map(async (doc: { file_path: string; }) => {
            try {
              const url = await getFileUrl('syorui', doc.file_path);
              return { ...doc, url };
            } catch (error) {
              console.error('Error fetching file URL:', error);
              return { ...doc, url: '', error: 'Failed to load document URL' };
            }
          }));
          setDocuments(documentsWithUrl);
        }
      } catch (error) {
        console.error('Error initializing documents:', error);
      }
    };
    init();
  }, [komuten, customer]);

  // ファイルを開く
  const handleFileOpen = async (filePath: string) => {
    const publicUrl = await getFilePublicUrl('syorui', filePath);
    if (publicUrl) {
      window.open(publicUrl, '_blank');
    }
  };

  return (
    <div {...handlers} style={{ height: '100%', width: '100%' }}>
      <>
        <AppBar position="static" sx={{ bgcolor: theme.palette.primary.main, elevation: 0, boxShadow: 'none' }}>
          <Toolbar sx={{ minHeight: '48px' }}>
            <Typography variant="subtitle1" color="inherit" noWrap sx={{ flexGrow: 1, textAlign: 'center' }}>
              書類一覧
            </Typography>
          </Toolbar>
        </AppBar>
        <Box sx={{ width: '90%', margin: '16px auto 0', marginBottom: '16px', }}>
          {documents.length > 0 ? (
            <List component="nav" sx={{ width: '100%', bgcolor: 'background.paper', borderRadius: 4, overflow: 'hidden' }}>
              {
                documents.map((doc, index) => (
                  <React.Fragment key={index}>
                    <ListItem
                      button
                      onClick={() => handleFileOpen(doc.file_path)} component="div"
                    >
                      <ListItemIcon>
                        <PictureAsPdf color="primary" />
                      </ListItemIcon>
                      <ListItemText
                        primary={doc.syorui_name} primaryTypographyProps={{ style: { color: theme.palette.secondary.main } }}
                        secondary={`登録日：${new Date(doc.created_at).toLocaleDateString('ja-JP', {
                          year: 'numeric',
                          month: '2-digit',
                          day: '2-digit'
                        })}`}
                      />
                    </ListItem>
                    <Divider />
                  </React.Fragment>
                ))
              }
            </List>
          ) : (
            // documents配列が空の場合に表示されるメッセージ
            <Typography variant="body1" style={{ textAlign: 'center', marginTop: theme.spacing(9) }}>
              まだ書類は登録されていません。
            </Typography>
          )}
        </Box>
      </>
    </div>
  );
};

export default Keiyakusho;


// 書類０のばあいの確認とNotFoundページの設定