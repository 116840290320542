import AppBar, { AppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar/Toolbar";
import { FC } from "react";
import { Box, Typography } from "@mui/material";
import React from "react";

export type AuthHeaderProps = object & AppBarProps;

const AuthHeader: FC<AuthHeaderProps> = (props) => {
  return (
    <AppBar
      // color="transparent"
      position="static"
      elevation={0}
      sx={{
        borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
        // backgroundColor: "white",
      }}
    >
      <Toolbar>
        <Box textAlign={{ sx: "left" }} sx={{ flexGrow: 1, textAlign: "left" }}>
          <Typography variant="h6" noWrap>Prema Fans</Typography>
          {/* <img src={logoIcon} alt="" /> */}
        </Box>
        {/* <Tooltip title="使い方を確認する">
          <IconButton sx={{ color: "white" }} onClick={handleOpenUserMenu}>
            <HelpOutlineOutlinedIcon />
          </IconButton>
        </Tooltip> */}
      </Toolbar>
    </AppBar>
  );
};
export default AuthHeader;
