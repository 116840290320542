import React, { useCallback, useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  List,
  ListItem,
  ListItemText,
  Typography
} from '@mui/material';
import { useRecoilValue } from 'recoil';
import { komutenState } from '../recoil/komutenState';
import { getPointGetWay } from '../api/pointGet';

function PointGetDialog({ open, handleClose }: { open: boolean, handleClose: () => void }) {
  const komuten = useRecoilValue(komutenState);
  const [pointGetWayList, setPointGetWayList] = useState<any[]>([]);

  const fetchPointGetWay = useCallback(async () => {
    const komutenUid = komuten?.komuten_uid || 0;
    if (!komutenUid) return;
    const { data, error } = await getPointGetWay(komutenUid);

    if (error) {
      console.error(error);
      alert('データの取得時にエラーが発生しました。もう一度お試しください。');
    } else {
      setPointGetWayList(data);
    }
  }, [komuten]);

  useEffect(() => {
    if (komuten && komuten.komuten_uid) {
      fetchPointGetWay();
    }
  }, [open, komuten, fetchPointGetWay]);

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle>ポイント獲得方法</DialogTitle>
      <DialogContent>
        {pointGetWayList.length > 0 ? (
          <List>
            {pointGetWayList.map((item) => (
              <ListItem key={item.id}>
                <ListItemText
                  primary={item.way}
                  secondary={
                    <Typography component="span" style={{ color: '#4CAF50' }}>
                      {item.give_point}付与
                    </Typography>
                  }
                />
              </ListItem>
            ))}
          </List>
        ) : (
          <Typography>
            現在準備中のため、少々お待ちください。
          </Typography>
        )}
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose} style={{ color: 'black' }}>
          閉じる
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default PointGetDialog;
