import React, { FC, ReactNode } from "react";
import {
  Box,
  Theme,
  useMediaQuery,
} from "@mui/material";
import SideBar from "./sideBar";

interface PageContainerProps {
  children: ReactNode;
  isWide?: boolean;
  onlyContent?: boolean;
}

const PageContainer: FC<PageContainerProps> = (props) => {
  const { children, isWide = false, onlyContent = true } = props;
  const isPhone = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

  return (
    <Box
      display={"flex"}
      alignItems={"stretch"}
      overflow={"scroll"}
      width={"100vw"}
    >
      {!isPhone && onlyContent && <SideBar />}
      <Box
        display={"flex"}
        flexDirection={"column"}
        flexGrow={1}
        maxHeight={"100vh"}
        height={"100vh"}
        minWidth={isPhone ? "0" : "960px"}
        sx={{
          // backgroundColor: "#FAFAFA",
          overflowY: "auto",
        }}
      >
        {/* {onlyContent && <Header />} */}
        <Box
          maxWidth={isWide ? "none" : "1261px"} // isWide に基づいて maxWidth を動的に設定
          width={"100%"}
          marginInline={"auto"}
          paddingInline={3}
          paddingBlock={5}
          display={"grid"}
          gap={2}
        >
          {children}
        </Box>
      </Box>
      {/* {isPhone && (
        <BottomNavigation
          showLabels
          color="primary"
          sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }}
        >
          <BottomNavigationAction label="探索" icon={<SearchOutlined />} />
          <BottomNavigationAction
            label="アップロード"
            icon={<UploadOutlined />}
          />
          <BottomNavigationAction
            label="通知"
            icon={<NotificationsOutlined />}
          />
          <BottomNavigationAction
            label="アカウント"
            icon={<AccountCircleOutlined />}
          />
        </BottomNavigation>
      )} */}
    </Box>
  );
};

export default PageContainer;
