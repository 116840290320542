import React, { useEffect, useState } from 'react';
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import AuthPasswordForGet from "./pages/sakuzyo/authPasswordForget";
import DraftDetail from "./pages/sakuzyo/draftDetail";
import SettingDefault from "./pages/sakuzyo/settingDefault";
import Home from "./pages/home";
import PointHistory from "./pages/pointHistory";
import AccountSetting from "./pages/accountSetting";
import Faq from "./pages/faq";
import Syorui from "./pages/syorui";
import PrivacyPolicy from "./pages/privacyPolicy";
import { PATHS } from "./paths";
import AuthAccount from "./pages/sakuzyo/authAccount";
import AccountList from "./pages/sakuzyo/accountList";
import AccountDetail from "./pages/sakuzyo/accountDetail";
import PromptDetail from "./pages/sakuzyo/promptDetail";
import AuthPasswordReset from "./pages/sakuzyo/authPasswordReset";
import liff from "@line/liff/core";
import GetIdToken from "@line/liff/get-id-token";
import { useRecoilState } from 'recoil';
import { getKomutenData } from './service/komutenService';
import { komutenState } from './recoil/komutenState';
import { insertCustomer } from './api/edgeFunctions';
import { getCustomer } from './service/customerService';
import { customerState } from './recoil/customerState';
import NotFoundPage from './pages/notFoundPage';

function AppRoutes() {
  return (
    <Routes>
      {/* マイページHOME */}
      <Route path="/" element={<Home />} />
      <Route path="/point-history" element={<PointHistory />} />
      <Route path="/account-setting" element={<AccountSetting />} />
      <Route path="/faq" element={<Faq />} />
      <Route path="/syorui" element={<Syorui />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="*" element={<NotFoundPage />} />

      <Route path={`${PATHS.HOME}/:id`} element={<DraftDetail />} />

      {/* 認証 */}
      {/* <Route path={PATHS.AUTH_LOGIN} element={<AuthLogin />} /> */}
      <Route path={PATHS.AUTH_PASSWORD_RESET} element={<AuthPasswordReset />} />
      <Route
        path={PATHS.AUTH_PASSWORD_FORGET}
        element={<AuthPasswordForGet />}
      />
      <Route path={PATHS.AUTH_ACCOUNT} element={<AuthAccount />} />
      {/* 管理者 */}
      <Route path={PATHS.PRONPT} element={<PromptDetail />} />
      <Route path={PATHS.ACCOUNT} element={<AccountList />} />
      <Route path={`${PATHS.ACCOUNT}/:id`} element={<AccountDetail />} />
      {/* <Route path={`${PATHS.ACCOUNT_DETAIL}/:mode`} element={<AccountDetail />} /> */}
      <Route path={PATHS.SETTING_USER} element={<SettingDefault />} />
    </Routes>
  );
}

const theme = createTheme({
  palette: {
    primary: {
      main: "#f43c4c",
      contrastText: "#fcfcfc",
    },
    secondary: {
      main: "#14242c",
      contrastText: "#fcfcfc",
    },
    background: {
      default: "#F3F2F9",
      paper: "#fcfcfc",
    },
  },
  typography: {
    fontFamily: [
      "Roboto",
      '"Noto Sans JP"',
      '"Helvetica"',
      "Arial",
      "sans-serif",
    ].join(","),
  },
});

// アプリの全体のスタイルを設定するスタイルオブジェクト
const appStyle = {
  backgroundColor: theme.palette.background.default,
  color: theme.palette.text.primary, // テキストの色
  minHeight: '100vh', // 最小の高さをビューポートの100%に設定
};

function App() {
  const [, setKomuten] = useRecoilState(komutenState);
  const [error, setError] = useState('');
  const [, setCustomer] = useRecoilState(customerState);

  const hasReloaded = localStorage.getItem('hasReloaded') === 'true';

  // LINE ID TOKENの取得とエラーハンドリングを行う関数
  const getIdToken = () => {
    const idToken = liff.getIDToken();
    if (idToken === null) {
      throw new Error('LINE ID TOKENが取得できませんでした。');
    }
    return idToken;
  };

  useEffect(() => {
    const initialize = async () => {
      if (!process.env.REACT_APP_LIFF_ID) {
        console.error('The environment variable REACT_APP_LIFF_ID is missing.');
        setError('エラーが発生しました。');
        return;
      }

      try {
        //initより先に行う処理
        liff.use(new GetIdToken());

        await liff.init({
          liffId: process.env.REACT_APP_LIFF_ID,
          withLoginOnExternalBrowser: true,
        });

        //ローカルテストではコメントアウト
        // LIFF URLパラメータから工務店IDを取得
        const urlParams = new URLSearchParams(window.location.search);
        //開発ブラウザではnullだが、LINEでは取得できる
        let komutenId = urlParams.get('k_id');

        ///////////////////////ローカルテスト用
        // let komutenId = 'f34b45f3-d166-4321-ad5a-609d385039b2'; // 仮のID
        ////////////////////


        if (komutenId === null) {
          //home画面以外ではnullとなるのでrecoilから取得
          //ローカルストレージから取得
          komutenId = localStorage.getItem("komuten_uid")!;

          if (!komutenId) {
            setError('工務店IDが見つかりません。');
            return;
          }
        } else {
          //URLから取得した際に工務店IDを保存
          //LIFFアプリ立ち上げ時のみの想定ロジック 
          localStorage.setItem("komuten_uid", komutenId);
        }
        const { data, error } = await getKomutenData(komutenId);
        if (error) {
          setError('データの取得に失敗しました。');
          return;
        }
        setKomuten(data);
        document.title = data.name || "";

        ///////////////ローカルテスト用
        // const komutenId = 'f34b45f3-d166-4321-ad5a-609d385039b2'; // 仮のID
        // const { data, error } = await getKomutenData(komutenId);
        // if (error) {
        //   setError('データの取得に失敗しました。');
        //   return;
        // }
        // setKomuten(data);
        // document.title = data.name || "";
        //////////////////////

        ///////////////ローカルテスト用
        // let lineUid = 'U9dc6d6cc801a69fafd8d55b570d1b775';
        /////////////////

        //ローカルテストではコメントアウト(komutenIdがnull:lineUidで保存されている)
        let lineUid = localStorage.getItem(komutenId);

        //検証時はLINEのキャッシュが残っているとif文に入らないので、ローカルストレージを削除する(LINE→設定→トーク→データの削除→キャッシュ削除)
        if (!lineUid) {
          // API関数を呼び出してcustomerテーブルにデータを挿入
          // LINEのuserProfileを取得
          const userLineProfile = await insertCustomer(komutenId, getIdToken());
          if (userLineProfile.error) {
            setError('データの保存に失敗しました。');
            return;
          }
          lineUid = userLineProfile.userProfile.sub;

          localStorage.setItem(komutenId, lineUid!);

          const userData = await getCustomer(lineUid!, komutenId);
          setCustomer(userData.data);
        } else {
          const userData = await getCustomer(lineUid!, komutenId);
          if (userData.data.length === 0) {
            //ローカルストレージにLINEのIDが残っているが、customerテーブルにデータがない場合
            localStorage.removeItem(komutenId);
            const userLineProfile = await insertCustomer(komutenId, getIdToken());
            if (userLineProfile.error) {
              setError('データの保存に失敗しました。');
              return;
            }
            lineUid = userLineProfile.userProfile.sub;

            localStorage.setItem(komutenId, lineUid!);

            const userData = await getCustomer(lineUid!, komutenId);
            setCustomer(userData.data);
          } else {
            //ローカルストレージにLINEのIDが残っていて、customerテーブルにデータがある場合
            setCustomer(userData.data);
          }
        }

        // 初期化処理が無事終了した場合、リロードフラグをリセット
        localStorage.removeItem('hasReloaded');
      } catch (err) {
        console.error('LIFF initialization failed', err);

        if (!hasReloaded) {
          localStorage.setItem('hasReloaded', 'true'); // ローカルストレージに記録
          window.location.reload(); // 一度だけリロード
        } else {
          setError('エラーが発生しました。再読み込みを行ってください。');
        }
      }
    };

    initialize();
  }, [setKomuten, setCustomer, hasReloaded]);

  if (error) {
    return <div>Error: {error}</div>; // エラーがある場合は、エラーメッセージを表示
  }

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider
        dateAdapter={AdapterDayjs}
        dateFormats={{ monthAndYear: "YYYY年MM月" }}
        adapterLocale="ja"
      >
        <div className="App" style={appStyle}>
          <BrowserRouter>
            <AppRoutes />
          </BrowserRouter>
        </div>
      </LocalizationProvider>
    </ThemeProvider>
  );
}

export default App;
