import axios, { AxiosResponse } from "axios";
import { getAxiosConfig } from "./config";
import {
  PRDetail,
  PRGenerate,
  PRSave as PRSaveRequest,
  PRRecreate,
  PRRecreateResponse,
  PRSaved as PRSaveResponse,
  PRPage,
} from "../../types/pr";

import { PRRequest } from "../../types/pr";

// getList
export const getPRList = async (pageNum: number): Promise<PRPage> => {


  const response = await axios.get(
    // `${process.env.REACT_APP_API_URL}/pr/get/page/${pageNum}`,
    `${process.env.REACT_APP_API_URL}/pr/get/page/${pageNum}`,
    getAxiosConfig()
  );

  console.log(response.data);

  return response.data;
};

// getDetail
export const getPRDetail = async (prid: string): Promise<PRDetail> => {
  const response = await axios.get(
    `${process.env.REACT_APP_API_URL}/pr/getpr/${prid}`,
    getAxiosConfig()
  );

  console.log(response.data);

  return response.data;
};

// create
export const createPRText = async (request: PRRequest): Promise<PRGenerate> => {
  // .5秒待つ
  // return await new Promise((resolve) => setTimeout(resolve, 500));

  const response = await axios.post(
    `${process.env.REACT_APP_API_URL}/pr/create`,
    {
      pr_target: request.pr_target,
      pr_content: request.pr_content,
      company_summary: request.company_summary,
      press_url_1: request.press_url_1,
      press_url_2: request.press_url_2,
      press_url_3: request.press_url_3,
      category: request.category,
    },
    getAxiosConfig()
  );

  console.log(response.data);

  return response.data;
};

// recreate
export const recreate = async (
  recreate: PRRecreate
): Promise<PRRecreateResponse> => {
  const response = await axios.post(
    `${process.env.REACT_APP_API_URL}/pr/recreate`,
    {
      title: recreate.title,
      table_of_contents: recreate.table_of_contents,
      summary: recreate.summary,
      details: recreate.details,
      demand: recreate.demand,
      category: recreate.category,
      recreate_part: {
        parent: recreate.recreate_part.parent,
        child: recreate.recreate_part.child,
      },
    },

    getAxiosConfig()
  );

  return response.data;
};

// save
export const savePR = async (data: PRSaveRequest): Promise<PRSaveResponse> => {
  const response = await axios.post(
    `${process.env.REACT_APP_API_URL}/pr/save`,
    {
      pr_input_id: data.pr_input_id,
      gpt_response_id: data.gpt_response_id,
      category: data.category,
      title: data.title,
      summary: data.summary,
      table_of_contents: data.table_of_contents,
      details: data.details,
      status: data.status,
      release_time: data.release_time,
      create_datetime: data.create_datetime,
      update_datetime: data.update_datetime,
    },
    getAxiosConfig()
  );

  return response.data;
};

// copy
export const copyPR = async (prid: string): Promise<PRRequest> => {
  const response = await axios.get(
    `${process.env.REACT_APP_API_URL}/pr/copy/${prid}`,
    getAxiosConfig()
  );

  return response.data.data;
};

// delete
export const deletePR = async (prid: number): Promise<AxiosResponse> => {
  return await axios.get(
    `${process.env.REACT_APP_API_URL}/pr/delete/${prid}`,
    getAxiosConfig()
  );
};
