import { supabase } from "../utils/supabase";

export const fetchBanner = async (komutenId: number) => {
  try {
    const { data, error } = await supabase
      .rpc('fetch_banner', { _komuten_id: komutenId });

    if (error) {
      console.error('Error fetching banner:', error);
      return []; // エラーがあった場合は空の配列を返す
    }
    return data || []; // dataがundefinedの場合は空の配列を返す
  } catch (error) {
    console.error('Fetch baner error:', error);
    return []; // 例外が発生した場合も空の配列を返す
  }
};
