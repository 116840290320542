import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {
  Alert,
  Box,
  Button,
  Card,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import AuthContainer from "../../components/authContainer";
import { useLocation, } from "react-router-dom";
import { changePassword, confirmPasswordAPI } from "../../api/sakuzyo/auth";

const AuthPasswordReset: React.FC = () => {

  const [passwordError, setPasswordError] = useState("");

  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setConfirmShowPassword] = useState(false);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const confirmCode = queryParams.get("confirmCode");
  const username = queryParams.get("username");

  // サーバー側でエンコードして、URLに含める
  // クライアント側でパスワードも含めてエンコードする
  // サーバー側でデコードして、パスワードを取得する

  const togglePasswordVisibility = () => {
    setShowNewPassword(!showNewPassword);
  };

  const togglePasswordConfirmVisibility = () => {
    setConfirmShowPassword(!showConfirmPassword);
  };

  const toggleOldPasswordVisibility = () => {
    setShowOldPassword(!showOldPassword);
  };

  const validatePassword = (password: string) => {
    // 半角英数字8桁以上、少なくとも1つの数字と1つの英字を含む
    const regex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;
    return regex.test(password);
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    console.log("パスワードの変更");

    // パスワードのバリデーションチェック
    if (!validatePassword(newPassword)) {
      setPasswordError(
        "パスワードは半角英数字8桁以上で、少なくとも1つの数字と1つの英字を含む必要があります。"
      );
      return;
    }

    // パスワードと確認用パスワードが一致するかチェック
    if (newPassword !== confirmPassword) {
      setPasswordError("新しいパスワードと確認用パスワードが一致しません。");
      return;
    }

    // エラーメッセージをクリア
    setPasswordError("");

    if (username && confirmCode) {
      confirmPasswordAPI(username, confirmCode, confirmPassword).then((res) => {
        console.log(res);
        if (res.status === 200) {
          // navigate(PATHS.AUTH_LOGIN);
        }
      });
    } else {
      changePassword(oldPassword, newPassword, confirmPassword).then((res) => {
        console.log(res);

        if (res.status === 200) {
          // navigate(PATHS.AUTH_LOGIN);
        }
      });
    }
  };

  return (
    <AuthContainer>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          alignContent: "center",
          height: "85vh",
        }}
      >
        <Card
          elevation={3}
          sx={{ margin: "auto", display: "flex", flexDirection: "column" }}
        >
          <Box
            width={"540px"}
            paddingInline={2}
            paddingBlock={4}
            display={"flex"}
            flexDirection={"column"}
            gap={2}
          >
            <>
              <Box mb={2}>
                <Typography variant="h5">
                  {username ? "パスワードの設定" : "パスワードの再設定"}
                </Typography>
                <Typography variant="body2" sx={{ color: "grey" }}>
                  {username ? "パスワード" : "新しいパスワード"}
                  と確認用パスワードを入力してください。
                </Typography>
              </Box>
              {passwordError && <Alert severity="error">{passwordError}</Alert>}
              <form onSubmit={handleSubmit}>
                <Box display={"flex"} flexDirection={"column"} gap={2}>
                  <TextField
                    sx={{ display: username ? "none" : "block" }}
                    type={showOldPassword ? "text" : "password"}
                    fullWidth
                    onChange={(e) => setOldPassword(e.target.value)}
                    label="現在のパスワード"
                    value={oldPassword}
                    autoComplete="off"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={toggleOldPasswordVisibility}
                            edge="end"
                          >
                            {showOldPassword ? (
                              <VisibilityOffIcon />
                            ) : (
                              <VisibilityIcon />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <TextField
                    type={showNewPassword ? "text" : "password"}
                    fullWidth
                    onChange={(e) => setNewPassword(e.target.value)}
                    label={username ? "パスワード" : "新しいパスワード"}
                    value={newPassword}
                    autoComplete="off"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={togglePasswordVisibility}
                            edge="end"
                          >
                            {showNewPassword ? (
                              <VisibilityOffIcon />
                            ) : (
                              <VisibilityIcon />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <TextField
                    type={showConfirmPassword ? "text" : "password"}
                    fullWidth
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    label="確認用パスワード"
                    value={confirmPassword}
                    autoComplete="off"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={togglePasswordConfirmVisibility}
                            edge="end"
                          >
                            {showConfirmPassword ? (
                              <VisibilityOffIcon />
                            ) : (
                              <VisibilityIcon />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <Button
                    sx={{
                      height: "42px",
                    }}
                    type="submit"
                    className="login-button"
                    variant="contained"
                    fullWidth
                  >
                    <Typography>確定</Typography>
                  </Button>
                </Box>
              </form>
            </>
            <Box
              display={"flex"}
              flexDirection={"column"}
              alignItems={"flex-start"}
            >
              <Button variant="text" onClick={() => { }}>
                <Typography
                  variant="body2"
                  sx={{ textDecoration: "underline" }}
                >
                  ログインへ戻る
                </Typography>
              </Button>
            </Box>
          </Box>
        </Card>
      </Box>
    </AuthContainer>
  );
};

export default AuthPasswordReset;
