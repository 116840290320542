import React, { useState, useEffect } from 'react';
import {
  Typography,
  Container,
  Box,
  AppBar,
  Toolbar,
  Button,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTheme } from '@mui/material/styles';
import { useSwipeable } from 'react-swipeable';
import { useNavigate } from 'react-router-dom';

const faqs = [
  {
    question: "新築を建てるときの費用はどれくらいですか？",
    answer: "新築の費用は土地の価格、建物の規模、使用する建材等によって異なりますが、一般的な住宅の場合、約2,000万円から3,500万円程度が目安となります。"
  },
  {
    question: "工務店とは、どのような役割を担うのですか？",
    answer: "工務店は建築プロジェクトを総合的に管理し、設計から施工、アフターサービスまで一貫して提供する役割を担います。"
  },
  {
    question: "リフォームとリノベーションの違いは何ですか？",
    answer: "「リフォーム」とは、老朽化した建物を新築の状態に戻すことをいい、「リノベーション」とは、既存の建物に対して新たな機能や価値を付け加える改装工事を意味します。"
  },
  {
    question: "耐震リフォームを行うメリットは何ですか？",
    answer: "耐震リフォームによって、地震の際の建物の倒壊リスクを減少させ、居住者の安全を確保することができます。"
  },
  {
    question: "古い家をリノベーションする際の注意点は何ですか？",
    answer: "構造の安全性を確認し、適切な補強を行うと共に、現代に求められる省エネ等の性能をクリアすることが重要です。"
  },
  {
    question: "結局、いくらなら家が建つの？",
    answer: "お客様の希望される広さや間取り、土地の条件などにより全てコミコミの金額をお知らせします。 お気軽に担当スタッフまでお申し付けください。"
  },
  {
    question: "二世帯住宅は建てられるの？",
    answer: "建てられます。基本的に普通の住宅と同じ坪単価で建築可能です。ただし、玄関を2か所にする、キッチンを追加する等は、オプションになります。くわしくは、担当スタッフまでお申し付けください。"
  },
  {
    question: "年収300万円だけれど、家は建てられるの？",
    answer: "お客様によります。目安としては、（年収×5）くらいを建築費としてお考えください。担当スタッフまでお申し付けください。 ※ローンのご利用は銀行の審査が必要となります。"
  },
  {
    question: "土地を持っていない人はどうするの？",
    answer: "ご安心ください。担当者が良い土地を探して参ります。何かご要望等ありましたら、事前にメモなどをして伝えていただくと、スムーズにご提案できます。"
  },
  {
    question: "リフォームと建替え、どちらがトクなの？",
    answer: "こ基本的には、リフォームのほうが解体費用や廃材処分費がかからない分、建て替えるより安くなります。 また、リフォームは予算に合わせて優先順位を決め、箇所を絞って工事が可能です。 さらに建て替えは、新築同様の手続きが必要なため、税金や登記費用など諸費用が発生するほか、固定資産税や不動産取得税など各種税金もかかります。"
  },
  {
    question: "着工から完成までの期間はどのくらい？",
    answer: "着工から完成までの期間は、約4カ月が目安となります。建物の規模等により変動することがあります。詳しくは、店頭でお聞き下さい。"
  },
  {
    question: "親の土地に建てようと思うのですが・・・・",
    answer: "親の土地を利用して家を建てる大きなメリットは、なんといっても家の購入にかかる費用が抑えられることです。デメリットは親の土地に家を建てる際に住宅ローンを利用する場合、担保が求められます。つまり、親の土地に抵当権（金融機関が不動産を担保にとる権利）を設定しないと、住宅ローンを借入れることはできません。"
  },
  {
    question: "持ち家と賃貸はどちらが得なの？",
    answer: "「いつかは持ち家に・・・・」とか、「賃貸の方が気が楽。」とか、多くの人が様々な考えを持っているようです。賃貸では、ローンなどの借入れがないため気が楽ですが、「老後も住めるのか？」「今、払っている家賃は掛け捨てではないのか？」などの不安があります。反対に家を建てる方は、計画性のない借入れや無理な借入れが危険です。 持ち家と賃貸では、どちらが得かは人それぞれの価値観や生活が違いますから、はっきりとは言えません。それぞれのメリット・デメリットを十分に理解した上で検討する必要があるでしょう。そこで大事な事は、ご夫婦やご家族で「一戸建てに住みたいか？」それとも「賃貸でいい。」と思うか、じっくりと話し合う事が先々の得になるのではないでしょうか？"
  },
  {
    question: "友人の大工さんに建築を依頼しようと思うのですが。",
    answer: "友人だと、ちょっとした融通がきいたりするので、安心ですね。しかし、ミス工事をしたり、欠陥の場所を仲が良いために言えなかったというケースがよくあります。末永く自分の家を見てくれるはずが、家を建てたらそれっきり見てくれないという事が起きます。正しい判断は、友人の会社に頼む場合、担当営業や大工さんは、友人でない人に依頼することです。これで割り切ったビジネスライクな話ができます。"
  },

];

const FaqAccordion: React.FC = () => {
  const navigate = useNavigate();
  const handlers = useSwipeable({
    onSwipedRight: () => navigate(-1)
  });
  const theme = useTheme();
  const [expandedIndices, setExpandedIndices] = useState<number[]>([]);

  const handleToggle = (index: number) => {
    const currentIndex = expandedIndices.indexOf(index);
    const newExpandedIndices = [...expandedIndices];

    if (currentIndex === -1) {
      newExpandedIndices.push(index);
    } else {
      newExpandedIndices.splice(currentIndex, 1);
    }

    setExpandedIndices(newExpandedIndices);
  };


  useEffect(() => {
    document.title = "ABC工務店";
  }, []);

  return (
    <div {...handlers} style={{ height: '100%', width: '100%' }}>
      <>
        <AppBar position="static" sx={{ bgcolor: theme.palette.primary.main, elevation: 0, boxShadow: 'none' }}>
          <Toolbar sx={{ minHeight: '48px' }}>
            <Typography variant="subtitle1" color="inherit" noWrap sx={{ flexGrow: 1, textAlign: 'center' }}>
              よくある質問
            </Typography>
          </Toolbar>
        </AppBar>
        <Container maxWidth="sm">
          <Box my={4}>
            {faqs.map((faq, index) => (
              <Box key={index} sx={{ bgcolor: theme.palette.background.paper, mb: 2, borderRadius: 4 }}>
                <Button
                  fullWidth
                  variant="text"
                  onClick={() => handleToggle(index)}
                  sx={{
                    borderRadius: 4,
                    bgcolor: expandedIndices.includes(index) ? theme.palette.primary.main : 'inherit',
                    color: expandedIndices.includes(index) ? theme.palette.secondary.contrastText : 'inherit',
                    '&:hover': {
                      bgcolor: theme.palette.primary.main,
                      color: theme.palette.primary.contrastText,
                    },
                    justifyContent: 'space-between', // ボタン内のコンテンツを両端に配置
                    pl: 2, // 左のパディングを設定
                    pr: 2, // 右のパディングを設定
                    minHeight: '50px', // ボタンの最小の高さを設定
                  }}
                >
                  <Typography sx={{ fontWeight: 'bold', textAlign: 'left' }}>
                    {faq.question}
                  </Typography>
                  <ExpandMoreIcon sx={{ color: theme.palette.primary.main }} />
                </Button>
                {expandedIndices.includes(index) && (
                  <Box p={2} sx={{ bgcolor: theme.palette.background.paper, borderRadius: 4 }}>
                    <Typography>{faq.answer}</Typography>
                  </Box>
                )}
              </Box>
            ))}
          </Box>
        </Container>
      </>
    </div>
  );
}

export default FaqAccordion;
