import { atom } from 'recoil';

export const komutenState = atom<Komuten | null>({
    key: 'komutenState', // 一意のID
    default: null, // デフォルト値
});

type PointRank = {
    rank: string;
    points: number;
  }[];
  
type Komuten = {
    name: string;
    name_kana: string;
    id: number;
    email: string;
    komuten_uid: string;
    tell_number: string;
    point_rate: string;
    account_id: string;
    password: string;
    created_at: Date;
    updated_at: Date;
    point_rank: PointRank;
    banner_number: number;
};
