import React, { FC, useState, useEffect } from 'react';
import {
    Box,
    Card,
    CardContent,
    CircularProgress,
    IconButton,
    Typography,
    useTheme
} from '@mui/material';
import rankColorTheme from '../utils/rankColorTheme';
import { useRecoilValue } from 'recoil';
import { komutenState } from '../recoil/komutenState';
import { customerState } from '../recoil/customerState';
import { SavingsOutlined } from '@mui/icons-material';
import PointGetDialog from '../dialog/pointGetDialog';

interface PointRankCardProps {
    showPointInfo?: boolean;
}

const PointRankCard: FC<PointRankCardProps> = ({ showPointInfo = true }) => {
    const theme = useTheme();
    const komuten = useRecoilValue(komutenState);
    const customer = useRecoilValue(customerState);
    const [rankName, setRankName] = useState('');
    const [hoyuPoint, setHoyuPoint] = useState(0);
    const [, setRuisekiPoint] = useState(0);
    const [pointRate, setPointRate] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [open, setOpen] = useState(false);


    useEffect(() => {
        setPointRate(komuten?.point_rate || "");
        if (customer) {
            const hoyuPoints = customer?.hoyu_point || 0;
            setHoyuPoint(hoyuPoints);
            const ruisekiPoints = customer?.ruiseki_point || 0;
            setRuisekiPoint(ruisekiPoints);


            const currentRank = komuten?.point_rank?.slice().reverse().find(rank => ruisekiPoints >= rank.points);
            setRankName(currentRank?.rank || '');
            setIsLoading(false);
        }
    }, [komuten, customer]);

    const getBackgroundForRank = (rankName: string) => {
        switch (rankName) {
            case 'ブルー':
                return rankColorTheme.blue;
            case 'ブロンズ':
                return rankColorTheme.bronze;
            case 'シルバー':
                return rankColorTheme.silver;
            case 'ゴールド':
                return rankColorTheme.gold;
            case 'プラチナ':
                return rankColorTheme.platinum;
            default:
                return rankColorTheme.blue;
        }
    };


    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    if (isLoading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
                <CircularProgress />
            </Box>
        );
    }

    return (
        <Card
            sx={{
                boxShadow: 0,
                width: '90%',
                margin: '0 auto',
                marginBottom: '16px',
                borderRadius: 4,
                background: getBackgroundForRank(rankName),
                position: 'relative',
                overflow: 'hidden',
                ...(rankName === 'プラチナ' && {
                    '&:before': {
                        content: '""',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundImage: 'linear-gradient(45deg, rgba(255, 255, 255, 0.25) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.25) 50%, rgba(255, 255, 255, 0.25) 75%, transparent 75%, transparent)',
                        backgroundSize: '20px 20px',
                        opacity: 0.2,
                        zIndex: 1,
                    },
                    '&:after': {
                        content: '""',
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        bottom: 0,
                        left: 0,
                        backgroundImage: 'radial-gradient(circle at center, rgba(255, 255, 255, 0.35) 0%, transparent 80%)',
                        zIndex: 1,
                    },
                }),
            }}
        >
            <CardContent
                sx={{
                    textAlign: 'center',
                    color: theme.palette.secondary.main,
                    position: 'relative',
                    zIndex: 1,
                }}
            >
                {showPointInfo && (
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                        <IconButton onClick={handleClickOpen} sx={{ color: theme.palette.secondary.main }}>
                            <SavingsOutlined />
                            <Typography
                                component="span"
                                sx={{
                                    ml: 1, fontSize: '15px', color: theme.palette.secondary.main,
                                    textDecoration: 'underline',
                                }}
                            >
                                ポイント獲得について
                            </Typography>
                        </IconButton>
                        <PointGetDialog open={open} handleClose={handleClose} />
                    </Box>
                )}
                <Typography variant="body2" sx={{ textAlign: 'left', pl: 3, fontWeight: 'bold', color: theme.palette.secondary.main }}>
                    現在のランク
                </Typography>
                <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 1, color: theme.palette.secondary.main }}>
                    {rankName}
                </Typography>
                <Typography variant="body2" sx={{ textAlign: 'left', pl: 3, fontWeight: 'bold', mb: 1, color: theme.palette.secondary.main }}>
                    保有ポイント
                </Typography>
                <Typography variant="h4" sx={{ fontWeight: 'bold', mb: 1, color: theme.palette.secondary.main }}>
                    {hoyuPoint.toLocaleString()}pt
                </Typography>
                <Typography sx={{ textAlign: 'right', fontSize: '15px', mb: 0, color: theme.palette.secondary.main }}>
                    {pointRate}
                </Typography>
            </CardContent>
        </Card>
    );
};

export default PointRankCard;
