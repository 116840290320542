import { supabase } from "../utils/supabase";

//アカウント設定更新
export const updateAccountSetting = async (customerData: { customer_id: any; name: any; name_kana: any; post_number: any; zyusyo: any; kotei_tell_number: any; tell_number: any; }) => {
  const { data, error } = await supabase
    .rpc('update_account_setting', {
      _customer_id: customerData.customer_id,
      _name: customerData.name,
      _name_kana: customerData.name_kana,
      _post_number: customerData.post_number,
      _zyusyo: customerData.zyusyo,
      _kotei_tell_number: customerData.kotei_tell_number,
      _tell_number: customerData.tell_number,
    });

  if (error) {
    console.error('Error updating account setting:', error.message);
    throw new Error('更新に失敗しました。もう一度お試しください。');
  }

  console.log('Account setting updated successfully', data);
};
