import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {
  Box,
  Button,
  Card,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import Alert from "@mui/material/Alert";
import React, { useState } from "react";
import AuthContainer from "../../components/authContainer";

const AuthPasswordForGet: React.FC = () => {
  // routerの定義

  // stateの管理
  const [isError] = useState(false);
  const [password, setPassword] = useState("");
  const [maskedPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [maskedPasswordConfirm,] = useState("");
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
  const [showPasswordResetComponent,] =
    useState(false);

  const [loginID, setEmail] = useState("");

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    // navigate(PATHS.AUTH_LOGIN);
  };

  return (
    <AuthContainer>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          alignContent: "center",
          height: "85vh",
        }}
      >
        <Card
          elevation={3}
          sx={{ margin: "auto", display: "flex", flexDirection: "column" }}
        >
          <Box
            width={"540px"}
            paddingInline={2}
            paddingBlock={4}
            display={"flex"}
            flexDirection={"column"}
            gap={2}
          >
            {!showPasswordResetComponent ? (
              <>
                <Box mb={2}>
                  <Typography variant="h5">パスワードの再設定</Typography>
                  <Typography variant="body2" sx={{ color: "grey" }}>
                    登録時に使用したメールアドレスを入力してください。パスワード再設定の手順を送信します
                  </Typography>
                </Box>
                <form onSubmit={handleSubmit}>
                  <Box display={"flex"} flexDirection={"column"} gap={2}>
                    <TextField
                      fullWidth
                      value={loginID}
                      type="text"
                      id="email-input"
                      onChange={(e) => setEmail(e.target.value)}
                      label="ログインID(メールアドレス)"
                      autoComplete="off"
                    ></TextField>
                    <Button
                      sx={{
                        height: "42px",
                      }}
                      type="submit"
                      className="login-button"
                      variant="contained"
                      fullWidth
                    >
                      <Typography>送信</Typography>
                    </Button>
                  </Box>
                </form>
              </>
            ) : (
              <>
                <Box mb={2}>
                  <Typography variant="h5">パスワードの再設定</Typography>
                  <Typography variant="body2" sx={{ color: "grey" }}>
                    新しいパスワードと確認用パスワードを入力してください。
                  </Typography>
                </Box>
                {isError ? (
                  <Alert severity="error">
                    過去に使用したパスワードはご利用になれません。
                  </Alert>
                ) : null}
                <form onSubmit={handleSubmit}>
                  <Box display={"flex"} flexDirection={"column"} gap={2}>
                    <TextField
                      type={showPassword ? "text" : "password"}
                      fullWidth
                      onChange={(e) => setPassword(e.target.value)}
                      label="新しいパスワード"
                      value={showPassword ? password : maskedPassword}
                      autoComplete="off"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() => setShowPassword(!showPassword)}
                              edge="end"
                            >
                              {showPassword ? (
                                <VisibilityOffIcon />
                              ) : (
                                <VisibilityIcon />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    ></TextField>
                    <TextField
                      type={showPasswordConfirm ? "text" : "password"}
                      fullWidth
                      onChange={(e) => setPasswordConfirm(e.target.value)}
                      label="確認用パスワード"
                      value={
                        showPasswordConfirm
                          ? passwordConfirm
                          : maskedPasswordConfirm
                      }
                      autoComplete="off"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() =>
                                setShowPasswordConfirm(!showPasswordConfirm)
                              }
                              edge="end"
                            >
                              {showPassword ? (
                                <VisibilityOffIcon />
                              ) : (
                                <VisibilityIcon />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    ></TextField>
                    <Button
                      sx={{
                        height: "42px",
                      }}
                      type="submit"
                      className="login-button"
                      variant="contained"
                      fullWidth
                    >
                      <Typography>確定</Typography>
                    </Button>
                  </Box>
                </form>
              </>
            )}
            <Box
              display={"flex"}
              flexDirection={"column"}
              alignItems={"flex-start"}
            >
              <Button variant="text" onClick={() => { }}>
                <Typography
                  variant="body2"
                  sx={{ textDecoration: "underline" }}
                >
                  ログインへ戻る
                </Typography>
              </Button>
            </Box>
          </Box>
        </Card>
      </Box>
    </AuthContainer>
  );
};

export default AuthPasswordForGet;
