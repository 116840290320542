import { supabase } from "../utils/supabase";

export async function getKomutenData(komuten_uid: string,) {
    const { data, error } = await supabase
        .from('komuten')
        .select('*')
        .eq('komuten_uid', komuten_uid)
        .single();

    if (error) {
        console.error('Error fetching data:', error);
        // エラーメッセージを返す
        return { data: null, error: error.message };
    }

    // 成功時はエラーを含まない
    return { data, error: null };
}
