import React, { FC, useCallback, useEffect, useState } from 'react';
import {
  List,
  ListItemText,
  Typography,
  ListItemIcon,
  Divider,
  ListItemButton,
  AppBar,
  Toolbar,
  Box,
  Link,
  Grid,
  CircularProgress
} from '@mui/material';
import {
  AccountCircleOutlined,
  ArticleOutlined,
  HelpOutline,
  PrivacyTipOutlined,
  ChevronRight,
} from '@mui/icons-material';
import { useNavigate, } from "react-router-dom";
import { PATHS } from "../paths";
import { useTheme } from '@mui/material/styles';
import { ReactComponent as PointIcon } from '../assets/point.svg';
import { useRecoilValue } from 'recoil';
import { komutenState } from "../recoil/komutenState";
import PointRankCard from '../components/pointRankCard';
import { fetchBanner } from '../api/banner';
import { getFileUrl } from '../service/storageService';
import { customerState } from '../recoil/customerState';

export type HomeProps = object;

const Home: FC<HomeProps> = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const komuten = useRecoilValue(komutenState);
  const [isLoading, setIsLoading] = useState(true);
  const [bannerDataList, setBannerDataList] = useState<Array<{ url: string, hp_url: string }>>([]);
  const customer = useRecoilValue(customerState);

  const fetchBannerList = useCallback(async () => {
    try {
      if (!komuten) {
        return;
      }
      const banners = await fetchBanner(komuten.id);
      if (banners.length === 0) {
        return;
      }
      const bannerDataList = await Promise.all(
        banners.map(async (doc: { file_path: string; hp_url: string; }) => {
          try {
            const url = await getFileUrl('banner', doc.file_path);
            // hp_url を含むオブジェクトを返す
            return { url, hp_url: doc.hp_url };
          } catch (error) {
            console.error('Error fetching file URL:', error);
            return { url: '', hp_url: doc.hp_url, error: 'Failed to load document URL' };
          }
        })
      );
      setBannerDataList(bannerDataList);
    } catch (error) {
      console.error('Error fetchBannerList:', error);
      alert('データの読み込みに失敗しました。もう一度お試しください。');
    }
  }, [komuten]);


  useEffect(() => {
    document.title = komuten?.name || "";
    fetchBannerList();
    setIsLoading(false);
  }, [komuten, fetchBannerList]);

  const listItems = [
    { text: "アカウント設定", icon: <AccountCircleOutlined />, path: PATHS.ACCOUNT_SETTING },
    { text: "書類一覧", icon: <ArticleOutlined />, path: PATHS.SYORUI },
    { text: "ポイント履歴", icon: <PointIcon style={{ height: '23px', width: '23px' }} />, path: PATHS.POINT_HISTORY },
    { text: "よくある質問", icon: <HelpOutline />, path: PATHS.FAQ },
    { text: "プライバシーポリシー", icon: <PrivacyTipOutlined />, path: PATHS.PRIVACY_POLICY },
  ];

  return (
    <>
      <AppBar position="static" sx={{ bgcolor: theme.palette.primary.main, elevation: 0, boxShadow: 'none' }}>
        <Toolbar sx={{ minHeight: '48px' }}>
          <Typography variant="subtitle1" color="inherit" noWrap sx={{ flexGrow: 1, textAlign: 'center' }}>
            マイページ
          </Typography>
        </Toolbar>
      </AppBar>
      {isLoading ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: 'calc(100vh - 48px)',
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <div style={{ padding: '16px 0' }}>
          <PointRankCard showPointInfo={false} />
          {customer && customer.name === '' && (
            <Box
              sx={{
                bgcolor: 'warning.light',
                color: 'warning.contrastText',
                border: `1px solid ${theme.palette.warning.main}`,
                p: 2,
                mb: 2,
                borderRadius: 2,
                maxWidth: '90%',
                mx: 'auto',
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                justifyContent: 'center',
              }}
            >
              {/* <WarningAmber /> */}
              <Typography variant="body2" textAlign="center" fontWeight="bold" sx={{ fontSize: '0.8rem' }}>
                アカウント設定にて氏名のご登録をお願いします。
              </Typography>
            </Box>
          )}
          <Box sx={{ width: '90%', margin: '0 auto', marginBottom: '16px', }}>
            <List component="nav" sx={{ width: '100%', bgcolor: 'background.paper', borderRadius: 4 }}>
              {listItems.map((item, index) => (
                <React.Fragment key={item.text}>
                  <ListItemButton sx={{ my: 1, py: 1, width: '100%' }} onClick={() => navigate(item.path)}>
                    <ListItemIcon>
                      {React.cloneElement(item.icon, { sx: { color: theme.palette.primary.main } })}
                    </ListItemIcon>
                    <ListItemText primary={item.text} />
                    <ListItemIcon sx={{ justifyContent: 'flex-end' }}>
                      <ChevronRight sx={{ color: theme.palette.primary.main }} />
                    </ListItemIcon>
                  </ListItemButton>
                  {index < listItems.length - 1 && <Divider component="li" />}
                </React.Fragment>
              ))}
            </List>
          </Box>
          <Box sx={{
            width: '90%',
            margin: '0 auto',
            marginBottom: '16px',
            backgroundColor: theme.palette.background.paper,
            borderRadius: 4,
            overflow: 'hidden'
          }}>
            <Box sx={{ padding: '16px', maxWidth: '1280px', margin: '0 auto' }}>
              <Grid container spacing={2} justifyContent="center">
                {bannerDataList.slice(0, komuten?.banner_number).map((banner, index) => (
                  <Grid item xs={6} key={index}>
                    <Link href={banner.hp_url} target="_blank" style={{ textDecoration: 'none' }}>
                      <Box
                        sx={{
                          overflow: 'hidden',
                          borderRadius: 2,
                          boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                          transition: 'transform 0.3s ease-in-out',
                          '&:hover': {
                            transform: 'scale(1.05)',
                            boxShadow: '0 8px 16px rgba(0,0,0,0.2)',
                          },
                        }}
                      >
                        <img src={banner.url} alt="バナー" style={{ width: '100%', height: 'auto', display: 'block' }} />
                      </Box>
                    </Link>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Box>
        </div>
      )}
    </>
  );
};

export default Home;
