import { supabase } from "../utils/supabase";

//ファイル取得
export const getFileUrl = async (bucketName: string, path: string): Promise<string> => {
    const { data } = supabase
        .storage
        .from(bucketName)
        .getPublicUrl(path);

    return data.publicUrl || '';
};

//ダウンロードのために公開URLを取得
export const getFilePublicUrl = async (bucketName: string, filePath: string) => {
    try {
        const response = supabase.storage.from(bucketName).getPublicUrl(filePath);
        const publicUrl = response.data?.publicUrl;
        if (!publicUrl) {
            console.error('Error getting file public URL: URL is not available');
            return '';
        }
        return publicUrl;
    } catch (error) {
        console.error('Error getting file public URL:', error);
        return '';
    }
};
