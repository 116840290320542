export async function insertCustomer(komutenId: string, lineIdToken: string) {
  
  const response = await fetch(process.env.REACT_APP_EDGE_FUNCTION_URL ?? '', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ komutenId, lineIdToken }),
  });

  if (!response.ok) {
    // レスポンスの内容をテキスト形式で取得し、エラーメッセージとして扱う
    const errorText = await response.text();
    console.error("Error Response Text:", errorText);
    throw new Error('データの保存に失敗しました。');
  }
  // レスポンスボディが JSON 形式の場合、ここで初めて読み込む
  return response.json();
}
