import axios, { AxiosResponse } from 'axios'
import {
  clearToken,
  getAxiosConfig,
  getRefreshToken,
  setAccessToken,
  setCompanyID,
  setRefreshToken,
  setTokenDatetime,
} from './config'

export const login = async (
  email: string,
  password: string,
): Promise<AxiosResponse> => {
  // eslint-disable-next-line no-useless-catch
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/auth/login`,
      {
        username: email,
        password: password,
      },
    )

    // 必要に応じて認証トークンを保存する処理をここに追加
    setAccessToken(response.data.AccessToken)
    setRefreshToken(response.data.RefreshToken)
    setTokenDatetime()
    setCompanyID(response.data.company_id)

    return response
  } catch (error) {
    // エラーハンドリング
    throw error
  }
}

export const logout = async (): Promise<AxiosResponse> => {
  const response = await axios.post(
    `${process.env.REACT_APP_API_URL}/auth/logout`,
    {},
    getAxiosConfig(),
  )

  clearToken()

  return response
}

export const changePassword = async (
  old_password: string,
  new_password: string,
  confirm_password: string,
): Promise<AxiosResponse> => {
  const response = await axios.post(
    `${process.env.REACT_APP_API_URL}/auth/change-password`,
    {
      old_password: old_password,
      new_password: new_password,
      confirm_password: confirm_password,
    },
    getAxiosConfig(),
  )

  return response
}

export const confirmPasswordAPI = async (
  email: string,
  confirm_code: string,
  password: string,
): Promise<AxiosResponse> => {
  console.log(email)
  console.log(confirm_code)
  console.log(password)

  const url = `${process.env.REACT_APP_API_URL}/auth/confirm-password`
  const queryString = `?email=${email}&confirm_code=${confirm_code}&password=${password}`
  const endpoint = decodeURI(url + queryString)

  const response = await axios.post(endpoint, {})

  return response
}

export const refreshToken = async (): Promise<AxiosResponse> => {
  const refresh_token = getRefreshToken()

  const response = await axios.post(
    `${process.env.REACT_APP_API_URL}/auth/refresh_token`,
    {
      refresh_token: refresh_token,
    },
    getAxiosConfig(),
  )

  setAccessToken(response.data.AccessToken)
  setTokenDatetime()
  console.log('tokenRefreshed')

  return response
}

// /api/v1/auth/change-password
// /api/v1/auth/confirm-email
