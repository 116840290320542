import { supabase } from "../utils/supabase";

export const getCustomer = async (line_uid: string,komuten_uid: string) => {
  try {

    const response = await supabase
      .from("customer")
      .select("*")
      .eq("komuten_uid", komuten_uid)
      .eq("line_uid", line_uid)
      .single();
    if (response.error) {
      throw response.error;
    }

    return {
      data: response.data,
    };
  } catch (error) {
    console.error("Error get customer data:", error);
    // エラーが発生した場合は、エラーメッセージを含むオブジェクトを返す
    return { data: [], };
    // return { data: [],  error: error || "An error occurred while fetching customer data." };
  }
};
