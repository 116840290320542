import React, { useEffect } from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  Card,
  useTheme,
  Box
} from '@mui/material';
import { useSwipeable } from 'react-swipeable';
import { useNavigate } from 'react-router-dom';

const PrivacyPolicy: React.FC = () => {
  const navigate = useNavigate();
  const handlers = useSwipeable({
    onSwipedRight: () => navigate(-1)
  });
  const theme = useTheme();

  useEffect(() => {
    document.title = "ABC工務店";
  }, []);

  return (
    <div {...handlers} style={{ height: '100%', width: '100%' }}>
      <>
        <AppBar position="static" sx={{ bgcolor: theme.palette.primary.main, elevation: 0, boxShadow: 'none' }}>
          <Toolbar sx={{ minHeight: '48px' }}>
            <Typography variant="subtitle1" color="inherit" noWrap sx={{ flexGrow: 1, textAlign: 'center' }}>
              プライバシーポリシー
            </Typography>
          </Toolbar>
        </AppBar>
        <Box sx={{ width: '90%', mx: 'auto', boxShadow: 'none', mt: 3 }}>
          <Card sx={{ width: '100%', boxShadow: 0, backgroundColor: (theme) => theme.palette.background.paper, borderRadius: 4, padding: '0px 16px 16px 16px' }}>
            <div>
              <h2>プライバシーポリシー</h2>
              <p>制定日: 2024/04/30</p>
              <p>リノアークグループ株式会社（以下、「当社」という）は、お客様のプライバシーを尊重し、保護することを約束します。本プライバシーポリシーは、Rennectアプリ（以下、「当アプリ」という）の利用に関する情報収集、使用、および開示に関する方針を説明します。当アプリをご利用いただくことで、本ポリシーに記載された情報の収集および使用に同意したものとみなされます。</p>
              <h3>1. 収集する情報</h3>
              <p>当アプリでは、以下の情報を収集する場合があります：</p>
              <ul>
                <li>個人情報: お名前、連絡先情報（電話番号、メールアドレスなど）、住所など、個人を特定する情報。</li>
                <li>デバイス情報: デバイスの種類、OSバージョン、利用ブラウザなど。</li>
                <li>利用履歴: 当アプリの利用履歴や操作履歴。</li>
              </ul>
              <h3>2. 収集した情報の使用</h3>
              <p>当アプリで収集した情報は、以下の目的で使用されます：</p>
              <ul>
                <li>サービス提供およびカスタマーサポートのため。</li>
                <li>アプリの改善および新機能の開発のため。</li>
                <li>セキュリティおよび不正防止のため。</li>
                <li>法的要件や規制に対応するため。</li>
              </ul>
              <h3>3. 情報の共有および開示</h3>
              <p>当アプリで収集した情報は、以下の場合に限り、第三者と共有されることがあります：</p>
              <ul>
                <li>利用者の同意がある場合。</li>
                <li>法的要件や規制に従うため。</li>
                <li>弊社の業務委託先やパートナー企業との間で情報を共有する場合。</li>
              </ul>
              <h3>4. クッキーと類似技術</h3>
              <p>当アプリでは現在クッキーなどのトラッキング技術を使用しておりませんが、将来的に使用する可能性がある場合は、利用者に事前に通知し、同意を求めることとします。</p>
              <h3>5. ユーザーの権利</h3>
              <p>当アプリの利用者は、以下の権利を有します：</p>
              <ul>
                <li>個人情報へのアクセス。</li>
                <li>個人情報の訂正。</li>
                <li>個人情報の削除。</li>
              </ul>
              <p>これらの権利を行使するための詳細な手順は、当社のウェブサイトまたはアプリ内で提供されます。</p>
              <h3>6. 変更通知</h3>
              <p>本プライバシーポリシーは、予告なく変更される場合があります。重要な変更がある場合には、当アプリ上で通知されることがあります。</p>
              <h3>7. お問い合わせ</h3>
              <p>本プライバシーポリシーに関するご質問やお問い合わせがある場合は、下記の連絡先までお気軽にご連絡ください：</p>
              <p><strong>当アプリ プライバシーポリシー担当</strong></p>
              <p><strong>Email:</strong> contact@rennect.jp</p>
            </div>
          </Card>
        </Box>
        <Box sx={{ height: '24px' }} />
      </>
    </div>
  );
}

export default PrivacyPolicy;
