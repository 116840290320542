import { supabase } from "../utils/supabase";

export const fetchDocuments = async (customerId: number) => {
  try {
    const { data, error } = await supabase
      .rpc('fetch_documents_for_customer', { _customer_id: customerId });

    if (error) {
      console.error('Error fetching documents:', error);
      return []; // エラーがあった場合は空の配列を返す
    }
    return data || []; // dataがundefinedの場合は空の配列を返す
  } catch (error) {
    console.error('Fetch documents error:', error);
    return []; // 例外が発生した場合も空の配列を返す
  }
};
