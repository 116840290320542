import { atom } from 'recoil';

export const customerState = atom<Customer | null>({
    key: 'customerState', // 一意のID
    default: null, // デフォルト値
});


type Customer= {
    id: number;
    name: string;
    nick_name: string;
    name_kana: string;
    post_number: string;
    zyusyo: string;
    kotei_tell_number: string;
    tell_number: string;
    kubun: string;
    tantosya: string;
    history: string;
    created_at: Date;
    updated_at: Date;
    komuten_uid: number;
    ruiseki_point: number;
    hoyu_point: number;
    setting_state: boolean;
  };