const rankColorTheme = {
    blue: 'linear-gradient(135deg, #2188ff 0%, #6ea8ff 25%, #9bc5ff 50%, #6ea8ff 75%, #2188ff 100%)',
    bronze: 'linear-gradient(135deg, #b08d57 0%, #c8a676 25%, #e0c295 50%, #c8a676 75%, #b08d57 100%)',
    silver: 'linear-gradient(135deg, #CACDCE 0%, #E3E5E5 25%, #FFFFFF 50%, #E3E5E5 75%, #CACDCE 100%)',
    gold: 'linear-gradient(135deg, #EDE0C8 0%, #EFE1B3 25%, #FFF4D0 50%, #EFE1B3 75%, #EDE0C8 100%)',
    platinum: `linear-gradient(135deg,
      #B3B6B9 0%,
      #808488 25%,
      #F0F0F0 50%,
      #808488 75%,
      #B3B6B9 100%)`,
};
  
  export default rankColorTheme;
  