import { FC, useState } from 'react'

import {
  Box,
  Button,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from '@mui/material'
import { useLocation } from 'react-router-dom'
import { createAccount } from '../../api/sakuzyo/account'
import PageContainer from '../../components/pageContainer'
import { Account } from '../../types/accounts'
import { InputMode } from '../../types/inputMode'

export type AccountDetailProps = {
  // mode?: InputMode;
}

const AccountDetail: FC<AccountDetailProps> = () => {
  const [email, setEmail] = useState('')
  const [firstname, setFirstname] = useState('')
  const [lastname, setLastname] = useState('')
  const [companyName, setCompanyName] = useState('')

  const location = useLocation()
  // URLSearchParamsを使用してクエリパラメータを解析
  const queryParams = new URLSearchParams(location.search)
  const mode = queryParams.get('mode') as InputMode
  const [openSnackbar, setOpenSnackbar] = useState(false)

  return (
    <PageContainer>
      <Box>
        <Typography
          variant="h4"
          component="div"
          sx={{ flexGrow: 1, textAlign: 'left', marginBottom: 1 }}
        >
          アカウント管理
        </Typography>
        <Typography
          sx={{
            color: 'gray',
            paddingBottom: '20px',
          }}
        >
          アカウント管理
        </Typography>
      </Box>
      <Box
        padding={3}
        display={'grid'}
        rowGap={2}
        border={1}
        borderColor={'#0000001A'}
      >
        <Box
          display={'flex'}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <Typography variant="h5">基本情報</Typography>
          {mode === InputMode.CREATE && (
            <Button
              variant="contained"
              onClick={() => {
                const data: Account = {
                  email: email,
                  firstname: firstname,
                  lastname: lastname,
                  company_name: companyName,
                }

                createAccount(data).then((res) => {
                  // console.log(res);
                  // navigate(PATHS.ACCOUNT);
                  setOpenSnackbar(true)
                })
              }}
            >
              招待を送信する
            </Button>
          )}
          {mode === InputMode.READ && <Button variant="outlined">編集</Button>}
          {mode === InputMode.UPDATE && (
            <Button variant="contained">更新</Button>
          )}
          {mode === InputMode.DELETE && (
            <Button variant="contained" color="error">
              削除
            </Button>
          )}
        </Box>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>会社名</TableCell>
              <TableCell>
                <TextField
                  value={companyName}
                  onChange={(e) => setCompanyName(e.target.value)}
                  fullWidth
                ></TextField>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>氏名</TableCell>
              <TableCell sx={{ display: 'flex', columnGap: 1 }}>
                <TextField
                  value={lastname}
                  onChange={(e) => setLastname(e.target.value)}
                  placeholder="姓"
                  fullWidth
                ></TextField>
                <TextField
                  value={firstname}
                  onChange={(e) => setFirstname(e.target.value)}
                  placeholder="名"
                  fullWidth
                ></TextField>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>メールアドレス</TableCell>
              <TableCell>
                <TextField
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  fullWidth
                ></TextField>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Box>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={1000}
        message="招待メールの送信が完了いたしました。"
        onClose={() => setOpenSnackbar(false)}
      />
    </PageContainer>
  )
}
export default AccountDetail
