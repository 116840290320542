import { FC, useState } from 'react'

import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from '@mui/material'
import PageContainer from '../../components/pageContainer'
import DraftDetailCreate from './draftDetailCreate'
import DraftDetailEdit from './draftDetailEdit'
import DraftDetailPreview from './draftDetailPreview'
import DraftDetailResult from './draftDetailResult'
import { useLocation, useNavigate, } from 'react-router-dom'
// import { copyPR } from '../api/pr'
import {
  PRDetail,
  PRFirst,
  PRGenerate,
  PRLast,
  PRRequest,
  PRSaved,
} from '../../types/pr'
// import { InputMode } from '../types/inputMode'

export type DraftDetailProps = object

const DraftDetail: FC<DraftDetailProps> = (props) => {
  // const { id } = useParams()
  const navigate = useNavigate()
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const [prDetail, setPRDetail] = useState<PRDetail>()
  const [prGenerate, setPRGenerate] = useState<PRGenerate | null>(null)

  // クエリパラメータから初期タブを取得し、ない場合はデフォルト値を設定
  const initialTab = queryParams.get('tab') || 'create'
  // const copyID = queryParams.get('copy_id')
  // const mode = queryParams.get('mode') as InputMode
  const [selectedTab, setSelectedTab] = useState(initialTab)

  // タブを選択したときにURLを更新
  const handleTabChange = (tabId: string) => {
    setSelectedTab(tabId)
    const currentSearchParams = new URLSearchParams(location.search)
    currentSearchParams.set('tab', tabId)
    const url = `${location.pathname}?${currentSearchParams.toString()}`
    navigate(url)
  }

  // const setCopyPR = () => {
  //   if (!copyID) return

  //   copyPR(copyID).then((res) => {
  //     const detail: PRDetail = {
  //       release_time: '',
  //       status: 'draft',
  //       message: '',
  //       gpt_response_id: 0,
  //       pr_input_id: 0,
  //       data: {
  //         pr_id: 0,
  //         request: res,
  //         first: {
  //           title: '',
  //           summary: '',
  //           details: {},
  //           table_of_contents: {},
  //         },
  //         last: {
  //           title: '',
  //           summary: '',
  //           details: {},
  //           table_of_contents: {},
  //           status: '',
  //         },
  //       },
  //     }
  //     setPRDetail(detail)
  //   })
  // }

  const setSavedPR = (saved: PRSaved) => {
    const tempPRDetail = JSON.parse(JSON.stringify(prDetail))
    tempPRDetail.message = saved.message
    tempPRDetail.release_time = Date.now()
    tempPRDetail.data.pr_id = saved.data.pr_id
    tempPRDetail.data.first.title = saved.data.first.title
    tempPRDetail.data.first.summary = saved.data.first.summary
    tempPRDetail.data.first.details = saved.data.first.details
    tempPRDetail.data.first.table_of_contents =
      saved.data.first.table_of_contents

    tempPRDetail.data.last.title = saved.data.last.title
    tempPRDetail.data.last.summary = saved.data.last.summary
    tempPRDetail.data.last.details = saved.data.last.details
    tempPRDetail.data.last.table_of_contents = saved.data.last.table_of_contents

    setPRDetail(tempPRDetail)
  }

  const setGenerate = (generate: PRGenerate, request: PRRequest) => {
    setPRGenerate(generate)

    const first: PRFirst = {
      title: generate.data.title,
      summary: generate.data.summary,
      details: generate.data.details,
      table_of_contents: generate.data.table_of_contents,
    }

    const last: PRLast = {
      title: generate.data.title,
      summary: generate.data.summary,
      details: generate.data.details,
      table_of_contents: generate.data.table_of_contents,
      status: 'draft',
    }

    setPRDetail({
      release_time: '',
      message: '',
      status: 'draft',
      gpt_response_id: generate.gpt_response_id,
      pr_input_id: generate.pr_input_id,
      data: {
        pr_id: 0,
        request: request,
        first: first,
        last: last,
      },
    })
  }

  const listItems = [
    {
      id: 'create',
      text: '新規作成',
      component: (
        <DraftDetailCreate
          data={prDetail}
          generate={prGenerate}
          handleTabChange={handleTabChange}
          setGenerate={setGenerate}
        />
      ),
    },
    {
      id: 'first',
      text: '編集',
      component: (
        <DraftDetailEdit
          data={prDetail}
          generate={prGenerate}
          handleTabChange={handleTabChange}
          setSavedPR={setSavedPR}
        />
      ),
    },
    {
      id: 'preview',
      text: 'プレビュー',
      component: (
        <DraftDetailPreview
          detail={prDetail}
          handleTabChange={handleTabChange}
        />
      ),
    },
    {
      id: 'result',
      text: '初回出力結果',
      component: <DraftDetailResult detail={prDetail} />,
    },
  ]

  // useEffect(() => {
  //   console.log('mode', mode)

  //   if (mode === InputMode.CREATE || !id) return

  //   if (mode === InputMode.COPY) {
  //     setCopyPR()
  //     handleTabChange('create')
  //     return
  //   }

  //   getPRDetail(id).then((res) => {
  //     console.log(res)
  //     setPRDetail(res) // 取得したデータをstateに設定
  //     handleTabChange(res.data.last?.status === 'draft' ? 'first' : 'preview')
  //   })

  //   return () => {}
  // }, [id])

  return (
    <PageContainer>
      <Box>
        <Typography
          variant="h4"
          component="div"
          sx={{ flexGrow: 1, textAlign: 'left', marginBottom: 1 }}
        >
          プレスリリース新規作成
        </Typography>
        <Typography
          sx={{
            color: 'gray',
            paddingBottom: '20px',
          }}
        >
          プレスリリース管理
        </Typography>
      </Box>
      <Box
        display={'flex'}
        justifyContent={'space-between'}
        // alignItems={"center"}
        border={1}
        borderColor={'#0000001A'}
      >
        <List
          sx={{
            borderRight: 1,
            borderColor: '#0000001A',
            width: '180px',
            padding: 1,
          }}
        >
          {listItems.map((item) => (
            <ListItem disablePadding key={item.id}>
              <ListItemButton
                disabled={
                  (selectedTab === 'create' &&
                    (item.id === 'result' ||
                      item.id === 'preview' ||
                      item.id === 'first')) ||
                  (selectedTab === 'first' &&
                    (item.id === 'result' ||
                      item.id === 'preview' ||
                      item.id === 'create')) ||
                  (selectedTab === 'preview' &&
                    (item.id === 'create' || item.id === 'first')) ||
                  (selectedTab === 'result' &&
                    (item.id === 'create' || item.id === 'first'))
                }
                sx={{
                  borderRadius: '4px',
                  width: '100%',
                  '&:hover': {
                    backgroundColor: '#5B98F414',
                  },
                  ...(selectedTab === item.id && {
                    backgroundColor: '#5B98F414',
                  }),
                }}
                onClick={() => handleTabChange(item.id)}
              >
                <ListItemText>
                  <Typography whiteSpace={'nowrap'}>{item.text}</Typography>
                </ListItemText>
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        {listItems.find((item) => item.id === selectedTab)?.component}
      </Box>
    </PageContainer>
  )
}
export default DraftDetail
