import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";
const { persistAtom } = recoilPersist();

export const isMenuOpenState = atom({
  key: "isMenuOpenState",
  default: true,
});

export const sidebarScrollPositionState = atom({
  key: "sidebarScrollPositionState",
  default: 0,
});

export const myRoleState = atom({
  key: "myRoleState",
  default: "",
  effects_UNSTABLE: [persistAtom],
});
