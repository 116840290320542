import { supabase } from "../utils/supabase";

//ポイント獲得方法の取得
export const getPointGetWay = async (komuten_uid: string) => {
  try {
    const { data, error } = await supabase
      .rpc("get_point_get_way_liff", { _komuten_uid: komuten_uid });

    if (error) {
      console.error('getPointGetWay error:', error.message);
      throw new Error(error.message);
    }

    return {
      data: data,
    };
  } catch (error) {
    console.error("Error fetching point get way list:", error);
    return { data: [], totalCount: 0, error: error || "An error occurred while fetching the data." };
  }
};
